<template>
    <div class="variety-odds">
        <el-card>
            <div slot="header" class="clearfix">
                <span>品种胜率</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="options"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：品种胜率为各品种盈利笔数除以该品种总交易笔数。</p>
                <p>
                    如何使用：建议更多关注胜率较高的品种，减少操作胜率较低的品种。建议经常关注。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import DateRange from '@/components/DateRange.vue'
  import {Loading} from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";
  import {varietyFormatter} from "../../utils/tool";

  export default {
    name: "VarietyOdds",
    data () {
      return {
        starLevel: 4,
        options: {
          credits: {
            enabled: false
          },
          chart: {
            renderTo: 'container',
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 5,
              beta: 5,
              depth: 30,
              viewDistance: 90
            },
            backgroundColor: null,
            plotBackgroundColor: null
          },
          title: false,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.2f}%</b>'
          },
          colors: ['#EB3E41'],
          xAxis: {
            categories: []
          },
          yAxis: {
            title: false,
            max: 100
          },
          series: [],
        }
      }
    },
    components: {
      HChart,
      DateRange
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.h-chart'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('varietyOdds', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          let categories = []
          let f1s = []
          Array.isArray(resData) && resData.map(item => {
            categories.push(varietyFormatter(item.code))
            if (item.f1 || item.f1 === 0) {
              f1s.push(item.f1*100)
            }
          })
          this.options.xAxis.categories = categories;
          this.options.series = [{
            name: '品种盈亏',
            data: f1s
          }];
          this.$refs.chart.redraw();
          loadingInstance.close();
        }
      },
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .variety-odds {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
